<footer>
    <div class="container">
        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-3">
                    <div class="copyright-item">
                        <p>© 2020 Todos los Derechos Reservados </p>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="copyright-item copyright-right">
                        <p>Diseño por Creather Estudio Creativo </p>
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>
        </div>
    </div>
</footer>
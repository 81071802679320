<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/Logonotaria.png" alt="Logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container" style="max-width: 90% !important;">
            <nav class="navbar navbar-expand-md navbar-light" id="navbar">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/Logonotaria.png" alt="Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="#nosotros" (click)="toUs()" class="nav-link">NOSOTROS</a>
                        </li>
                        <li class="nav-item">
                            <a href="#servicios" (click)="toServices()" class="nav-link" >SERVICIOS</a>
                        </li>
                        <li class="nav-item">
                            <a href="#contacto" (click)="toContact()" class="nav-link" >CONTACTO</a>
                        </li>

                           <ul class="mobile-footer-menu" id="social-media" style="display: none;">
                              <li>
                                <a href="https://www.google.com/maps/place/Juan+Sebasti%C3%A1n+Bach+237,+La+Loma,+58099+Morelia,+Mich.,+M%C3%A9xico/@19.680163,-101.185941,16z/data=!4m5!3m4!1s0x842d0da635868199:0x63694e3513121c5d!8m2!3d19.6801628!4d-101.1859413?hl=es">  
                                  <img src="assets/img/icon-location.png" alt="Logo">
                                </a>
                              </li>
                              <li>
                                <a href="https://api.whatsapp.com/send?phone=524433876089&text=Hola%2C%20necesito%20informaci%C3%B3n%2C%20gracias">  
                                  <img src="assets/img/icon-call.png" alt="Logo">
                                </a>
                              </li>
                              <li>
                                <a href="mailto:alfonsocvaca@notario145.com.mx">  
                                  <img src="assets/img/icon-email.png" alt="Logo">
                                </a>
                              </li>
                          </ul>

                    </ul>
<!--                     <div class="search-wrapper">
                        <span class="search-icon1" style="
  height: 2.8em;
  width: 2.8em;
  display: block;
  padding: 0.5em;
  margin: 1em auto;
  position: relative;
  cursor: pointer;
  border-radius: 4px;">
                      <span class="circle1"></span>
                      <span class="handle1"></span>
                      <div class="input-holder">
                            <input type="text" class="search-input" placeholder="Type to search" />

                    </div>
                    </span>
                    </div> -->
                    
<!--                     <div class="search-wrapper">
                        <div class="input-holder">
                            <input type="text" class="search-input" placeholder="Type to search" />
                            <button class="search-icon" onclick="searchToggle(this, event);"><span></span></button>
                        </div>
                        <span class="close" onclick="searchToggle(this, event);"></span>
                    </div> -->
                </div>
            </nav>

        </div>
    </div>

</div>
<style type="text/css">
    <style>
    .search-icon1 {
 
}

.circle1 {
  position: relative;
  display: block;
  height: 18px;
  width: 18px;

  border-radius: 70px;
  z-index: 0;
  border: 2px solid black;
  -webkit-transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: 1;
}

.handle1 {
  position: absolute;
  background-color: black;
  top: 27px;
      left: 19px;
  width: 18px;
  height: 3px;
  display: block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  float: right;
  border-radius: 2px;
}
.handle1:after {
  content: "";
  background-color: black;
  width: 18px;
  height: 3px;
  display: block;
  float: right;
  border-radius: 2px;
  -webkit-transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  transition: all 0.6s cubic-bezier(0.25, 1.7, 0.35, 0.8);
  z-index: -1;
}

.open .circle1 {
  -webkit-transform: scale(0.02);
          transform: scale(0.02);
  -webkit-transform-origin: 30% 40%;
          transform-origin: 30% 40%;
  background: white;
  border-color: white;
}
.open .handle1:after {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

    @media only screen and (min-width : 1200px) {

    .container {
    max-width: 90% !important;
}
}
</style>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }
     toServices(){
     document.getElementById("servicios").scrollIntoView();
     }
     toUs(){
     document.getElementById("nosotros").scrollIntoView();
     }
     toContact(){
     document.getElementById("contacto").scrollIntoView();
     }
  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_modal';
import slidersData from '../../../data/sliders.json';
import tabsData from '../../../data/tabs.json';

interface Slider {
    id: Number;
    title: String;
    content: String;
    img: String;
    buttontext: String;
    buttonurl: String;

}

interface Tabs {
id: Number;
title : String;
datarel : String;
class: String;

}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	    bodyText: string;
      sliders: Slider[] = slidersData;
      tabs: Tabs[] = tabsData;
    constructor(private modalService: ModalService) { }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

   toContact(){
     document.getElementById("contacto").scrollIntoView();
     }


  ngOnInit(): void {
  }

}


<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <span>404</span>
                    <p>La página no ha sido encontrada</p>
                    <a routerLink="/">INICIO</a>
                </div>
            </div>
        </div>
    </div>
</section>